<template>
  <div class="tongzhi-details">
    <proviewPdf
      v-if="tzzwShowPdf"
      ref="pdf"
      :pdf-url="zhengwentongzhi"
    ></proviewPdf>
    <div class="imgbox" v-if="!tzzwShowPdf"><img :src="zhengwentongzhi" alt=""></div>
  </div>
</template>

<script>
import proviewPdf from './components/proviewPdf';
import { mapState } from 'vuex';
export default {
  components: {
    proviewPdf
  },
  data () {
    return {
    };
  },
  created () {
    if (this.$route.query.preview || this.$store.state.policy.preview) {
      this.$store.commit('policy/SET_PREVIEW', '1');
    }
  },
  computed: {
    ...mapState('policy', [ 'zhengwentongzhi', 'tzzwShowPdf' ])
  },
  watch: {
  }
};
</script>

<style lang="less" scoped>
.tongzhi-details {
  // width: 100%;
  height: 75vh;
  border: 10px solid #F5F5F5;
  text-align:center;
}
.tongzhi-details .imgbox{
  width: 100%;
  height: 75vh;
  overflow: auto;
}

img{
  width: 1040px;
  // height: 75vh;
}
</style>
